/* eslint-disable max-len */
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { AlertOutlined, QuestionCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Affix } from 'antd';

import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import CreateAlertDrawerForSearchPage from '~/components/CreateAlertDrawerForSearchPage';
import PropertySearchResultPaginated from '~/components/PropertySearchResultPaginated';
import PropertySearchFeaturedAgentCard from '~/components/PropertySearchFeaturedAgentCard';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';
import FeaturedSearchResultAgent from '~/components/FeaturedSearchResultAgent';
import RealtyFindBABanner from '~/components/RealtyFindBABanner';

import emptySearch from './search.png';

const PropertySearchResultList = memo(
    ({
        searchOptions,
        propertySearch,
        featuredAgentData,
        featuredBrokerData,
        buildQueryVariables,
        onChangePagination,
        onChangeOfi,
        loading,
        isFromSearch,
        hideCloseButton,
        headerPadding,
        hidePropertiesList,
        propertySearchVariables,
    }) => {
        const [isScrolled, setIsScrolled] = useState(false);
        const [loadingMore, setLoadingMore] = useState(false);
        const [visible, setVisible] = useState(false);

        const { featuredAgentByLocation, randomAgentByLocation, previewFeaturedAgentByLocation } =
            featuredAgentData || {};

        const { featuredBrokerByLocation } = featuredBrokerData || {};
        const { acceleratePreviewAgentId, ref, locations, locationsData, pageSize } = searchOptions || {};

        const showLoadingMore = propertySearch?.pageInfo?.hasNextPage ?? false;
        let dataSource = propertySearch?.edges ?? [];

        const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;
        const hasPreviewFeaturedAgent = previewFeaturedAgentByLocation?.agent ?? false;
        const hasFeaturedAgent = featuredAgentByLocation?.agent ?? false;
        const hasRandomAgent = randomAgentByLocation?.agent ?? false;

        useEffect(() => {
            onChangeOfi(null);
        }, []);

        const handleLoadMore = async () => {
            const { propertiesConnection } = data || {};

            setLoadingMore(true);

            return new Promise(async (resolve) => {
                try {
                    await fetchMore({
                        variables: buildQueryVariables(searchOptions, propertiesConnection.pageInfo.endCursor),
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const { nodes } = fetchMoreResult.propertiesConnection;

                            return nodes.length
                                ? {
                                      ...previousResult,
                                      propertiesConnection: {
                                          ...fetchMoreResult.propertiesConnection,
                                          nodes: [...previousResult.propertiesConnection.nodes, ...nodes],
                                      },
                                  }
                                : previousResult;
                        },
                    });
                    resolve(true);
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingMore(false);
                }
            });
        };
        const handleDrawer = (bool) => {
            setVisible(bool);
        };

        const totalCount = propertySearch?.totalCount ?? 0;

        return (
            <>
                <CreateAlertDrawerForSearchPage
                    visible={visible}
                    onClose={() => handleDrawer(false)}
                    searchOptions={searchOptions}
                />
                <div className="PropertySearchResultList">
                    <div className="flex">
                        <div className="advance-search-result w-full sm:pb-2">
                            {hasFeaturedAgent && (
                                <div
                                    className={`sticky1 featured-agent-container z-30 bg-white pt-5 ${
                                        isScrolled ? 'scrolled' : ''
                                    }`}
                                >
                                    <div
                                        className={`${
                                            hasPreviewFeaturedAgent
                                                ? 'rounded-lg border-4 border-dashed border-red-400 p-1 text-right'
                                                : ''
                                        }`}
                                    >
                                        {hasPreviewFeaturedAgent && (
                                            <Alert
                                                message={`Sorry, ${featuredAgentByLocation.location.suburb} is already taken!, Search for another suburb above`}
                                                type="error"
                                                showIcon
                                            />
                                        )}
                                        <PropertySearchFeaturedAgentCard
                                            agent={featuredAgentByLocation.agent}
                                            location={featuredAgentByLocation.location}
                                            agency={
                                                featuredAgentByLocation.agent && featuredAgentByLocation.agent.agency
                                                    ? featuredAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={featuredAgentByLocation.agent.reviewRating}
                                            propertiesConnection={featuredAgentByLocation.agent.propertiesConnection}
                                        />
                                    </div>
                                    <Divider />
                                </div>
                            )}

                            {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                                <div className="sticky1 featured-agent-container z-30 bg-white pt-5">
                                    <div>
                                        <PropertySearchFeaturedAgentCard
                                            agent={randomAgentByLocation.agent}
                                            location={randomAgentByLocation.location}
                                            agency={
                                                randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                                    ? randomAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={randomAgentByLocation.agent.reviewRating}
                                            propertiesConnection={randomAgentByLocation.agent.propertiesConnection}
                                        />
                                    </div>
                                    <Divider />
                                </div>
                            )}

                            {!hasFeaturedAgent && hasRandomAgent && hasPreviewFeaturedAgent && (
                                <div className="sticky1 z-30 bg-white px-2 pt-5">
                                    <div className="rounded-lg border-4 border-dashed border-red-400 p-1 text-right">
                                        <div className="absolute -mt-4 ml-6 bg-white px-2 font-bold text-red-400">
                                            PREVIEW ONLY
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <Link
                                                href="/accelerate"
                                                as="/accelerate"
                                                target="__blank"
                                                className="ml-4 w-1/2 text-blue-600 hover:text-blue-800"
                                            >
                                                <QuestionCircleOutlined className="mr-2" />
                                                Learn More
                                            </Link>

                                            <Button
                                                type="primary"
                                                icon={<ShoppingCartOutlined />}
                                                className="m-1 mt-0 rounded border-green-500 bg-green-500 hover:border-green-400 hover:bg-green-400"
                                            >
                                                <Link
                                                    href={{
                                                        pathname: `/accelerate/buy/[agentId]`,
                                                        query: {
                                                            agentId: acceleratePreviewAgentId,
                                                            locationId: previewFeaturedAgentByLocation.location.id,
                                                            ref: ref || '',
                                                        },
                                                    }}
                                                    target="__blank"
                                                    className="text-white hover:text-white"
                                                >
                                                    Buy Now
                                                </Link>
                                            </Button>
                                        </div>
                                        <PropertySearchFeaturedAgentCard
                                            agent={previewFeaturedAgentByLocation.agent}
                                            location={previewFeaturedAgentByLocation.location}
                                            agency={
                                                previewFeaturedAgentByLocation.agent &&
                                                previewFeaturedAgentByLocation.agent.agency
                                                    ? previewFeaturedAgentByLocation.agent.agency
                                                    : null
                                            }
                                            reviews={previewFeaturedAgentByLocation.agent.reviewRating}
                                            propertiesConnection={
                                                previewFeaturedAgentByLocation.agent.propertiesConnection
                                            }
                                            showBuyButton
                                        />
                                    </div>
                                    <Divider />
                                </div>
                            )}

                            <ScreenWidthValueProvider xs={1} sm={2} md={3} lg={2} xl={2} xxl={3}>
                                {(subMatch) => (
                                    <PropertySearchResultPaginated
                                        maxItemsPerRow={subMatch}
                                        resultList={dataSource}
                                        searchOptions={searchOptions}
                                        searchFilters={buildQueryVariables(searchOptions)}
                                        pageSize={pageSize || 24}
                                        loadMore={handleLoadMore}
                                        loading={loading}
                                        showLoadingMore={showLoadingMore}
                                        isLoadingMore={loadingMore}
                                        isFromSearch={isFromSearch}
                                        totalCount={totalCount}
                                        onChangePagination={onChangePagination}
                                        featuredBroker={hasFeaturedBroker ? featuredBrokerByLocation : null}
                                        showBrokerOnMobile
                                        propertySearchVariables={propertySearchVariables}
                                        hidePropertiesList={hidePropertiesList}
                                    />
                                )}
                            </ScreenWidthValueProvider>

                            {!loading && dataSource.length === 0 && (
                                <div
                                    className="relative mt-2 rounded bg-white p-6 text-center"
                                    style={{ height: 'calc(100vh - 208px)' }}
                                >
                                    <div className="center-align">
                                        <img src={emptySearch} alt="empty search results" className="w-5/6" />
                                        <h2 className="mt-2 font-extralight text-gray-500">
                                            <div className="ml-6">
                                                <p className="my-4 text-xl">Sorry, no property results found</p>
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={() => handleDrawer(true)}
                                                    icon={<AlertOutlined />}
                                                >
                                                    Create alert
                                                </Button>
                                            </div>
                                        </h2>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="advance-search-rightside-content absolute right-0 top-0 hidden h-full w-2/5 pl-2 pt-16 md:pl-8 lg:block">
                        <div className="mt-8">
                            {hasFeaturedBroker && (
                                <div className="mb-8 max-w-xs">
                                    <PropertySearchBrokerCard
                                        firstName={featuredBrokerByLocation.broker.firstName}
                                        lastName={featuredBrokerByLocation.broker.lastName}
                                        suburbName={featuredBrokerByLocation.location.suburb}
                                        profilePicture={featuredBrokerByLocation.broker.profilePicture}
                                        brokerId={featuredBrokerByLocation.broker.id}
                                        logoUrl={featuredBrokerByLocation.broker.profileLogoUrl}
                                        headerPadding={headerPadding}
                                        slugUrl={featuredBrokerByLocation.broker.slugUrl}
                                        logoShape={featuredBrokerByLocation.broker.logoShape}
                                        hideCloseButton={hideCloseButton}
                                    />
                                </div>
                            )}
                            <div className="max-w-xs">
                                <Affix offsetTop={180}>
                                    {hasFeaturedAgent && (
                                        <div className="pb-10">
                                            <FeaturedSearchResultAgent
                                                agent={featuredAgentByLocation.agent}
                                                location={featuredAgentByLocation.location}
                                                agency={
                                                    featuredAgentByLocation.agent &&
                                                    featuredAgentByLocation.agent.agency
                                                        ? featuredAgentByLocation.agent.agency
                                                        : null
                                                }
                                            />
                                        </div>
                                    )}
                                    {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                                        <div className="pb-10">
                                            <FeaturedSearchResultAgent
                                                agent={randomAgentByLocation.agent}
                                                location={randomAgentByLocation.location}
                                                agency={
                                                    randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                                        ? randomAgentByLocation.agent.agency
                                                        : null
                                                }
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <RealtyFindBABanner />
                                        {/*<FeaturedSearchResultAlertAds />*/}
                                    </div>
                                </Affix>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

PropertySearchResultList.propTypes = {
    onChangePagination: PropTypes.func,
    loading: PropTypes.bool,
    onChangeOfi: PropTypes.func,
    propertySearch: PropTypes.object,
    featuredAgentData: PropTypes.object,
    featuredBrokerData: PropTypes.object,
    buildQueryVariables: PropTypes.func,
    isFromSearch: PropTypes.bool,
    totalCount: PropTypes.number,
    searchOptions: PropTypes.shape({
        sorting: PropTypes.string,
        group: PropTypes.string,
    }),
    hideCloseButton: PropTypes.string,
    headerPadding: PropTypes.string,
};

PropertySearchResultList.defaultProps = {
    searchOptions: {},
    propertySearch: {},
    featuredAgentData: {},
    featuredBrokerData: {},
    buildQueryVariables: {},
    onChangeOfi: () => {},
    onChangePagination: () => {},
    isFromSearch: false,
    loading: false,
    totalCount: 0,
    hideCloseButton: 'hidden',
    headerPadding: 'px-4 py-5',
};

export default PropertySearchResultList;
