import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Button } from 'antd';
import clsx from 'clsx';

import { trackEvent } from '~/lib/analytics';
import { getColorShade } from '~/utils/colors';
import PRIMARY from '~/constants/colors';

import AgentAvatar from '~/components/AgentAvatar';
import AgentEnquiryModal from '~/components/AgentEnquiryModal';

const FeaturedSearchResultAgent = ({ agent, agency, location, disableModal }) => {
    const [isShowEnquiryModal, setIsShowEnquiryModal] = useState(false);

    const handleClose = () => {
        setIsShowEnquiryModal(false);
    };

    const showEnquiryModal = () => {
        const { id: agentId } = agent || {};

        if (disableModal === false) {
            trackEvent('Attempted Agent Enquiry', {
                dimension_1: parseInt(agentId, 10),
                dimension_2: parseInt(agency.id, 10),
            });
            setIsShowEnquiryModal(true);
        }
    };

    const { firstName, lastName, profilePicture, slugUrl, initials } = agent || {};

    const { name, bannerColor, logoUrl, miniLogoUrl, slugUrl: agencySlugUrl } = agency || {};
    const { suburb } = location || {};
    const isNotWhiteColor = bannerColor && bannerColor !== '#fff';
    const cardStyle = {
        background: (isNotWhiteColor && bannerColor) || PRIMARY,
        color: (isNotWhiteColor && getColorShade(bannerColor)) || PRIMARY ? '#fff' : '#3D4852',
        borderColor: (isNotWhiteColor && bannerColor) || PRIMARY,
        // maxWidth: '100%',
        height: '45px',
    };

    return (
        <>
            <AgentEnquiryModal
                agent={agent}
                agency={agency}
                onClose={handleClose}
                visible={isShowEnquiryModal}
                bannerColor={bannerColor}
            />
            <div
                className={clsx('FeaturedSearchResultAgent overflow-hidden rounded-md bg-[#edf2f7]  shadow-md')}
                style={{ width: '300px' }}
            >
                <div className="flex h-10 w-full justify-center sm:rounded-t" style={{ ...cardStyle }}>
                    <div className="inline-block h-full rounded p-1 lg:max-w-max">
                        <Link href={`/agency/${agencySlugUrl}`}>
                            <img
                                src={miniLogoUrl || logoUrl}
                                alt={name}
                                className="h-full cursor-pointer"
                                style={{ objectFit: 'contain' }}
                            />
                        </Link>
                    </div>
                </div>
                <div className="flex ">
                    <div className="w-full items-center justify-between py-2 ">
                        <div className="block w-full px-6 py-4 text-center">
                            <Link href={`/agent/${slugUrl}`}>
                                <AgentAvatar
                                    agentAlt={`${firstName} ${lastName}`}
                                    initials={initials}
                                    agentSize={110}
                                    agentProfilePicture={profilePicture ? `${profilePicture}?w=100` : null}
                                    classNameForProfilePicture=" bg-gray-200"
                                    classNameForInitials=" bg-gray-200 sm:text-4xl text-3xl
                                agent-profile-picture-search"
                                />
                            </Link>
                            <div className="item-center flex justify-center">
                                <div className="flex flex-col justify-center">
                                    <div className="py-5 text-lg">
                                        Hi there, I&apos;m{' '}
                                        <span className="text-primary-500">
                                            {firstName} {lastName}
                                        </span>{' '}
                                        your {suburb} Real Estate agent
                                    </div>
                                    <div className="text-center">
                                        <Button
                                            type="primary"
                                            onClick={showEnquiryModal}
                                            className="text-overflow w-1/2 "
                                        >
                                            <span className="px-1 text-xs">Send Message</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

FeaturedSearchResultAgent.propTypes = {
    agent: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profilePicture: PropTypes.string,
        slugUrl: PropTypes.string,
        statistics: PropTypes.shape({
            listingCount: PropTypes.number,
            soldCount: PropTypes.number,
            medianSoldPrice: PropTypes.number,
        }),
    }),
    agency: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        bannerColor: PropTypes.string,
        agencyLogoUrl: PropTypes.string,
    }),
    location: PropTypes.shape({
        id: PropTypes.string,
        suburb: PropTypes.string,
    }),
    disableModal: PropTypes.bool,
};

FeaturedSearchResultAgent.defaultProps = {
    agent: {},
    agency: {},
    location: {},
    disableModal: false,
};

export default FeaturedSearchResultAgent;
