import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { EnvironmentOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { buildStateAddress, buildStreetAddress } from '~/utils/property';
import './OffMarketPropertyAddress.module.scss';

const OffMarketPropertyAddress = memo(({ property, newLine, icon, classNames, query, hasSubscription }) => {
    const { isShowAddress, uuid } = property || {};
    const { searchOptions } = query;
    const parseStreet = buildStreetAddress(property);
    const statePost = buildStateAddress(property);

    return (
        <Link
            // as={searchOptions ? `/off-market/${uuid}?searchOptions=${searchOptions}` : `/off-market/${uuid}`}
            href={{
                pathname: `/off-market/[id]`,
                query: {
                    id: uuid,
                    searchOptions,
                    ...query,
                },
            }}
            className={clsx('OffMarketPropertyAddress', 'w-full capitalize', classNames.main)}
        >
            {icon && (
                <EnvironmentOutlined className="mr-3 cursor-pointer text-lg text-blue-400 text-gray-700 hover:text-blue-500" />
            )}
            {hasSubscription && <span className={clsx('transition', classNames.street)}>{parseStreet}</span>}
            {!isShowAddress && (
                <span className={clsx('normal-case transition', classNames.street)}>Address available on request </span>
            )}
            {newLine && (parseStreet || !isShowAddress) && hasSubscription && <br />}
            {!newLine && (parseStreet || !isShowAddress) && ', '}
            <span className={clsx('transition', classNames.suburb)}>{statePost}</span>
        </Link>
    );
});

OffMarketPropertyAddress.propTypes = {
    classNames: PropTypes.shape({
        main: PropTypes.string,
        street: PropTypes.string,
        suburb: PropTypes.string,
    }),
    icon: PropTypes.bool,
    newLine: PropTypes.bool,
    property: PropTypes.shape({
        address: PropTypes.string,
        postalCode: PropTypes.string,
        suburb: PropTypes.string,
        street: PropTypes.string,
        state: PropTypes.string,
        streetNumber: PropTypes.string,
        isShowAddress: PropTypes.bool,
        subNumber: PropTypes.string,
    }),
    query: PropTypes.shape({
        isFromSearch: PropTypes.bool,
        searchOptions: PropTypes.string,
    }),
    hasSubscription: PropTypes.bool,
};

OffMarketPropertyAddress.defaultProps = {
    classNames: {
        main: 'text-overflow inline-block leading-normal',
        street: 'text-gray-800 font-medium',
        suburb: 'text-gray-700 font-normal',
    },
    icon: false,
    newLine: false,
    property: {},
    query: { isFromSearch: false, searchOptions: '' },
    hasSubscription: false,
};

export default OffMarketPropertyAddress;
