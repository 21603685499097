import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';
import RealtyFindBABanner from '~/components/RealtyFindBABanner';
import PropertySearchResultInspectionMobile from '../PropertySearchResultInspectionMobile';
import PropertyMobileOfiTime from '../PropertyMobileOfiTime/PropertyMobileOfiTime';
import ofiGraphic from './OFIgraphic.png';
import './PropertyInspectionMobile.module.scss';

const PropertyInspectionMobile = ({
    inspectionStatus,
    propertySearch,
    featuredAgentData,
    featuredBrokerData,
    onChangeOfi,
    searchOptions,
    buildQueryVariables,
    loading,
    onChangePagination,
    totalCount,
    onCloseDatePicker,
    onSubmitChangePropertyOfiSearchOptions,
    hideCloseButton,
    hidePropertiesList,
    propertySearchVariables,
}) => {
    const { ofiTime } = searchOptions || {};

    const { featuredBrokerByLocation } = featuredBrokerData || {};
    const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;

    return (
        <div className="PropertyInspectionMobile px-4 py-2">
            <Form>
                <PropertyMobileOfiTime
                    statusOfDatePicker={inspectionStatus}
                    onClose={onCloseDatePicker}
                    onSubmit={onSubmitChangePropertyOfiSearchOptions}
                    defaultDate={ofiTime}
                />
                {ofiTime && (
                    <PropertySearchResultInspectionMobile
                        propertySearch={propertySearch}
                        featuredAgentData={featuredAgentData}
                        featuredBrokerData={featuredBrokerData}
                        searchOptions={searchOptions}
                        buildQueryVariables={buildQueryVariables}
                        onChangeOfi={onChangeOfi}
                        loading={loading}
                        onChangePagination={onChangePagination}
                        totalCount={totalCount}
                        propertySearchVariables={propertySearchVariables}
                        hidePropertiesList={hidePropertiesList}
                    />
                )}
                {!ofiTime && (
                    <div className="relative mx-auto my-6 rounded bg-white p-6 text-center">
                        <div className="no-inspection">
                            <div className="m-auto max-w-md pb-6">
                                <img src={ofiGraphic} alt="no inspection" />
                            </div>
                            <div>
                                <p className="text-base">
                                    There are no properties matching your criteria that are open for inspection.
                                </p>
                                <p className="mt-4 text-sm">
                                    View open times for a different day of the week by selecting another day above.
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </Form>
            {hasFeaturedBroker && (
                <div className="w-full">
                    <PropertySearchBrokerCard
                        firstName={featuredBrokerByLocation.broker.firstName}
                        lastName={featuredBrokerByLocation.broker.lastName}
                        suburbName={featuredBrokerByLocation.location.suburb}
                        profilePicture={featuredBrokerByLocation.broker.profilePicture}
                        brokerId={featuredBrokerByLocation.broker.id}
                        logoUrl={featuredBrokerByLocation.broker.profileLogoUrl}
                        headerPadding="px-4 py-5"
                        slugUrl={featuredBrokerByLocation.broker.slugUrl}
                        logoShape={featuredBrokerByLocation.broker.logoShape}
                        hideCloseButton={hideCloseButton}
                    />
                </div>
            )}
            <div className="mt-8">
                <RealtyFindBABanner isMobile />
                {/* <img src={realtyBannerAdMobile} className="m-auto block" alt="realty alerts" /> */}
            </div>
        </div>
    );
};

PropertyInspectionMobile.propTypes = {
    searchOptions: PropTypes.object,
    propertySearch: PropTypes.object,
    featuredAgentData: PropTypes.object,
    featuredBrokerData: PropTypes.object,
    buildQueryVariables: PropTypes.func,
    onChangeOfi: PropTypes.func,
    onChange: PropTypes.func,
    changeSearchUrl: PropTypes.func,
    totalCount: PropTypes.number,
    hideCloseButton: PropTypes.string,
};

PropertyInspectionMobile.defaultProps = {
    searchOptions: {},
    propertySearch: {},
    featuredAgentData: {},
    featuredBrokerData: {},
    buildQueryVariables: {},
    totalCount: 0,
    onChangeOfi: () => {},
    onChange: () => {},
    changeSearchUrl: () => {},
    hideCloseButton: 'hidden',
};

export default PropertyInspectionMobile;
