/* eslint-disable max-len */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Divider } from 'antd';
import Link from 'next/link';
import dayjs from 'dayjs';
import { QuestionCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { roundNearestGroup } from '~/utils/number';
import PropertySearchFeaturedAgentCard from '~/components/PropertySearchFeaturedAgentCard';
import PropertySearchResultPaginated from '~/components/PropertySearchResultPaginated';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import PropertySortingSelect from '~/components/PropertySortingSelect';
import LoadingDots from '~/components/LoadingDots';
import ofiGraphic from './OFIgraphic.png';

const PropertySearchResultInspectionMobile = memo(
    ({
        searchOptions,
        propertySearch,
        featuredAgentData,
        featuredBrokerData,
        buildQueryVariables,
        onChangePagination,
        onChangeOfi,
        loading,
        isFromSearch,
        onChangeSorting,
        totalCount,
        hidePropertiesList,
        propertySearchVariables,
    }) => {
        const [isScrolled, setIsScrolled] = useState(false);
        const [loadingMore, setLoadingMore] = useState(false);

        const { featuredAgentByLocation, randomAgentByLocation, previewFeaturedAgentByLocation } =
            featuredAgentData || {};
        const { acceleratePreviewAgentId, ref, sorting, group, ofiTime } = searchOptions || {};
        const { featuredBrokerByLocation } = featuredBrokerData || {};
        const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;
        const hasPreviewFeaturedAgent = previewFeaturedAgentByLocation?.agent ?? false;
        const hasFeaturedAgent = featuredAgentByLocation?.agent ?? false;
        const hasRandomAgent = randomAgentByLocation?.agent ?? false;

        const showLoadingMore = propertySearch?.pageInfo?.hasNextPage ?? false;
        const dataSource = propertySearch?.edges ?? [];

        return (
            <div className="PropertySearchResultInspectionMobile">
                <div className="mt-6 flex justify-between px-4">
                    {loading && <LoadingDots className="text-left" />}
                    {!loading && (
                        <div className="text-left text-sm text-gray-800">
                            {totalCount > 0 && (
                                <>
                                    {`${totalCount >= 10 ? `${roundNearestGroup(totalCount)}+` : totalCount} Properties
                                `}
                                </>
                            )}
                        </div>
                    )}
                    <PropertySortingSelect
                        value={sorting || 'FEATURED'}
                        className="SortResultBy"
                        onChange={onChangeSorting}
                        type={group}
                    />
                </div>
                <div className="advance-search-result w-full px-5 sm:pb-2 md:px-8">
                    {hasFeaturedAgent && (
                        <>
                            <div
                                className={`sticky1 featured-agent-container z-30 bg-white pt-5 ${
                                    isScrolled ? 'scrolled' : ''
                                }`}
                            >
                                <div
                                    className={`${
                                        hasPreviewFeaturedAgent
                                            ? 'rounded-lg border-4 border-dashed border-red-400 p-1 text-right'
                                            : ''
                                    }`}
                                >
                                    {hasPreviewFeaturedAgent && (
                                        <Alert
                                            message={`Sorry, ${featuredAgentByLocation.location.suburb} is already taken!, Search for another suburb above`}
                                            type="error"
                                            showIcon
                                        />
                                    )}
                                    <PropertySearchFeaturedAgentCard
                                        agent={featuredAgentByLocation.agent}
                                        location={featuredAgentByLocation.location}
                                        agency={
                                            featuredAgentByLocation.agent && featuredAgentByLocation.agent.agency
                                                ? featuredAgentByLocation.agent.agency
                                                : null
                                        }
                                        mini={isScrolled}
                                        reviews={featuredAgentByLocation.agent.reviewRating}
                                        propertiesConnection={featuredAgentByLocation.agent.propertiesConnection}
                                    />
                                </div>
                            </div>
                            <Divider />
                        </>
                    )}

                    {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                        <>
                            <div className="sticky1 featured-agent-container z-30 bg-white pt-5">
                                <div>
                                    <PropertySearchFeaturedAgentCard
                                        agent={randomAgentByLocation.agent}
                                        location={randomAgentByLocation.location}
                                        agency={
                                            randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                                ? randomAgentByLocation.agent.agency
                                                : null
                                        }
                                        mini={isScrolled}
                                        reviews={randomAgentByLocation.agent.reviewRating}
                                        propertiesConnection={randomAgentByLocation.agent.propertiesConnection}
                                    />
                                </div>
                            </div>
                            <Divider />
                        </>
                    )}
                    {!hasFeaturedAgent && hasRandomAgent && hasPreviewFeaturedAgent && (
                        <>
                            <div className="sticky1 z-30 bg-white px-2 pt-5" style={{ top: offsetHeaderHeight }}>
                                <div className="rounded-lg border-4 border-dashed border-red-400 p-1 text-right">
                                    <div className="absolute -mt-4 ml-6 bg-white px-2 font-bold text-red-400">
                                        PREVIEW ONLY
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <Link
                                            href="/accelerate"
                                            as="/accelerate"
                                            target="__blank"
                                            className="ml-4 w-1/2 text-blue-600 hover:text-blue-800"
                                        >
                                            <QuestionCircleOutlined className="mr-2" />
                                            Learn More
                                        </Link>

                                        <Button
                                            type="primary"
                                            icon={<ShoppingCartOutlined />}
                                            className="m-1 mt-0 rounded border-green-500 bg-green-500 hover:border-green-400 hover:bg-green-400"
                                        >
                                            <Link
                                                href={{
                                                    pathname: `/accelerate/buy/[agentId]`,
                                                    query: {
                                                        agentId: acceleratePreviewAgentId,
                                                        locationId: previewFeaturedAgentByLocation.location.id,
                                                        ref: ref || '',
                                                    },
                                                }}
                                                target="__blank"
                                                className="text-white hover:text-white"
                                            >
                                                Buy Now
                                            </Link>
                                        </Button>
                                    </div>
                                    <PropertySearchFeaturedAgentCard
                                        agent={previewFeaturedAgentByLocation.agent}
                                        location={previewFeaturedAgentByLocation.location}
                                        agency={
                                            previewFeaturedAgentByLocation.agent &&
                                            previewFeaturedAgentByLocation.agent.agency
                                                ? previewFeaturedAgentByLocation.agent.agency
                                                : null
                                        }
                                        // mini={isScrolled}
                                        reviews={previewFeaturedAgentByLocation.agent.reviewRating}
                                        propertiesConnection={previewFeaturedAgentByLocation.agent.propertiesConnection}
                                        showBuyButton
                                    />
                                </div>
                            </div>
                            <Divider />
                        </>
                    )}

                    {/* {searchOptions.ofiTime && (
                        <PropertyOfiTime searchOptions={searchOptions} onChangeOfi={onChangeOfi} />
                    )} */}
                    <ScreenWidthValueProvider xs={1} sm={2} md={3} lg={2} xl={2} xxl={3}>
                        {(subMatch) => (
                            <PropertySearchResultPaginated
                                maxItemsPerRow={subMatch}
                                resultList={dataSource}
                                searchOptions={searchOptions}
                                searchFilters={buildQueryVariables(searchOptions)}
                                pageSize={24}
                                // loadMore={handleLoadMore}
                                loading={loading}
                                showLoadingMore={showLoadingMore}
                                isLoadingMore={loadingMore}
                                isFromSearch={isFromSearch}
                                totalCount={totalCount}
                                onChangePagination={onChangePagination}
                                featuredBroker={hasFeaturedBroker ? featuredBrokerByLocation : null}
                                showBrokerOnMobile={subMatch === 1}
                                propertySearchVariables={propertySearchVariables}
                                hidePropertiesList={hidePropertiesList}
                            />
                        )}
                    </ScreenWidthValueProvider>
                    {dataSource.length === 0 && (
                        <div className="relative mt-2 rounded bg-white p-6 text-center">
                            <div className="no-inspection">
                                <div className="m-auto max-w-md pb-6">
                                    <img src={ofiGraphic} alt="no inspection" />
                                </div>
                                <div>
                                    <p className="text-base">
                                        There are no properties matching your criteria that are open for inspection on{' '}
                                        {dayjs(ofiTime).format('dddd D MMM YYYY')}.
                                    </p>
                                    <p className="mt-4 text-sm">
                                        View open times for a different day of the week by selecting another day above.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

PropertySearchResultInspectionMobile.propTypes = {
    loading: PropTypes.bool,
    onChangeOfi: PropTypes.func,
    propertySearch: PropTypes.object,
    featuredAgentData: PropTypes.object,
    featuredBrokerData: PropTypes.object,
    onChangeSorting: PropTypes.func,
    buildQueryVariables: PropTypes.func,
    onChangePagination: PropTypes.func,
    isFromSearch: PropTypes.bool,
    totalCount: PropTypes.number,
    searchOptions: PropTypes.shape({
        sorting: PropTypes.string,
        group: PropTypes.string,
    }),
};

PropertySearchResultInspectionMobile.defaultProps = {
    searchOptions: {},
    propertySearch: {},
    featuredAgentData: {},
    featuredBrokerData: {},
    buildQueryVariables: {},
    onChangeOfi: () => {},
    onChangeSorting: () => {},
    onChangePagination: () => {},
    isFromSearch: false,
    loading: false,
    totalCount: 0,
};

export default PropertySearchResultInspectionMobile;
