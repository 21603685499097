import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { AlertOutlined, QuestionCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Alert, Button, Divider } from 'antd';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import CreateAlertDrawerForSearchPage from '~/components/CreateAlertDrawerForSearchPage';
import PropertySearchResultPaginated from '~/components/PropertySearchResultPaginated';
import PropertyMapWithMarkerClusterer from '~/components/PropertyMapWithMarkerClusterer';
import PropertySearchFeaturedAgentCard from '~/components/PropertySearchFeaturedAgentCard';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';
import emptySearch from './search.png';

const buildQueryVariables = (searchOptions) => {
    const {
        keywords,
        price,
        parking,
        landArea,
        bed,
        mapLocation,
        bathroom,
        ofiTime,
        group,
        locationsData,
        isIncludeSurrounding,
        isIncludeOffMarket,
        sorting,
        category,
        features,
        page,
    } = searchOptions;

    return {
        datesFrom: ofiTime,
        options: {
            page,
            pageSize: 24,
            bathroom,
            bed,
            garage: parking,
            group,
            isIncludeSurrounding,
            isIncludeOffMarket,
            keyword: keywords,
            landArea,
            location: locationsData?.map(({ id }) => id) ?? [],
            mapLocation,
            ofiTime,
            price,
            category,
            features,
            sorting: sorting ? `${sorting}`.toUpperCase() : null,
        },
    };
};

const PropertySearchResultMap = (props) => {
    const {
        loading,
        featuredAgentData,
        featuredBrokerData,
        searchOptions,
        isFromSearch,
        onChangePagination,
        hidePropertiesList,
        propertySearchVariables,
        propertySearch,
        markers,
    } = props;

    const { showMap, acceleratePreviewAgentId, ref } = searchOptions || {};

    // const locationIds = locationsData?.map(({ id }) => id) ?? [];

    // const { data: featuredBrokerData } = useQuery(PropertySearchFeaturedBrokerQuery, {
    //     ssr: false,
    //     skip: !(locationIds && locationIds.length > 0),
    //     variables: { locationIds },
    // });

    // const startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    // const endDate = dayjs().format('YYYY-MM-DD');

    // const { data: featuredAgentData } = useQuery(PropertySearchFeaturedAgentQuery, {
    //     ssr: false,
    //     skip: !(locationIds && locationIds.length > 0),
    //     variables: {
    //         filter: { first: 3 },
    //         agentId: acceleratePreviewAgentId,
    //         type: agentTypeByGroup[`${group}`.toLowerCase()] || 'DUAL',
    //         locationIds,
    //         randomOptions: {
    //             locationIds,
    //             type: agentTypeByGroup[`${group}`.toLowerCase()] || 'DUAL',
    //         },
    //         startDate,
    //         endDate,
    //     },
    // });

    const [isScrolled, setIsScrolled] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isOpenBroker, setIsOpenBroker] = useState(true);

    const { featuredAgentByLocation, randomAgentByLocation, previewFeaturedAgentByLocation } = featuredAgentData || {};
    const { featuredBrokerByLocation } = featuredBrokerData || {};
    const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;
    const hasPreviewFeaturedAgent = previewFeaturedAgentByLocation?.agent ?? false;
    const hasFeaturedAgent = featuredAgentByLocation?.agent ?? false;
    const hasRandomAgent = randomAgentByLocation?.agent ?? false;

    const handleDrawer = (bool) => {
        setVisible(bool);
    };

    let dataSource = propertySearch?.edges ?? [];
    if (loading) dataSource = [];

    // const markers = dataSource.filter(({ node: { lat } }) => lat !== null).map(({ node }) => node);
    const totalCount = propertySearch?.totalCount ?? 0;
    const showLoadingMore = propertySearch?.pageInfo?.hasNextPage ?? false;

    return (
        <>
            <CreateAlertDrawerForSearchPage
                visible={visible}
                onClose={() => handleDrawer(false)}
                searchOptions={searchOptions}
            />
            <div className="PropertySearchResultMap">
                <div className="flex">
                    <div className="advance-search-result pt-51 w-full sm:pb-2">
                        {hasFeaturedAgent && (
                            <>
                                <div
                                    className={`sticky1 featured-agent-container z-30 bg-white pt-5 ${
                                        isScrolled ? 'scrolled' : ''
                                    }`}
                                >
                                    <div
                                        className={`${
                                            hasPreviewFeaturedAgent
                                                ? 'rounded-lg border-4 border-dashed border-red-400 p-1 text-right'
                                                : ''
                                        }`}
                                    >
                                        {hasPreviewFeaturedAgent && (
                                            <Alert
                                                message={`Sorry, ${featuredAgentByLocation.location.suburb} is already taken!, Search for another suburb above`}
                                                type="error"
                                                showIcon
                                            />
                                        )}
                                        <PropertySearchFeaturedAgentCard
                                            agent={featuredAgentByLocation.agent}
                                            location={featuredAgentByLocation.location}
                                            agency={
                                                featuredAgentByLocation.agent && featuredAgentByLocation.agent.agency
                                                    ? featuredAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={featuredAgentByLocation.agent.reviewRating}
                                            propertiesConnection={featuredAgentByLocation.agent.propertiesConnection}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </>
                        )}

                        {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                            <>
                                <div className="sticky1 featured-agent-container z-30 bg-white pt-5">
                                    <div>
                                        <PropertySearchFeaturedAgentCard
                                            agent={randomAgentByLocation.agent}
                                            location={randomAgentByLocation.location}
                                            agency={
                                                randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                                    ? randomAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={randomAgentByLocation.agent.reviewRating}
                                            propertiesConnection={randomAgentByLocation.agent.propertiesConnection}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </>
                        )}

                        {!hasFeaturedAgent && hasRandomAgent && hasPreviewFeaturedAgent && (
                            <>
                                <div className="sticky1 z-30 bg-white px-2 pt-5">
                                    <div className="rounded-lg border-4 border-dashed border-red-400 p-1 text-right">
                                        <div className="absolute -mt-4 ml-6 bg-white px-2 font-bold text-red-400">
                                            PREVIEW ONLY
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <Link
                                                href="/accelerate"
                                                as="/accelerate"
                                                target="__blank"
                                                className="ml-4 w-1/2 text-blue-600 hover:text-blue-800"
                                            >
                                                <QuestionCircleOutlined className="mr-2" />
                                                Learn More
                                            </Link>

                                            <Button
                                                type="primary"
                                                icon={<ShoppingCartOutlined />}
                                                className="m-1 mt-0 rounded border-green-500 bg-green-500 hover:border-green-400 hover:bg-green-400"
                                            >
                                                <Link
                                                    href={{
                                                        pathname: `/accelerate/buy/[agentId]`,
                                                        query: {
                                                            agentId: acceleratePreviewAgentId,
                                                            locationId: previewFeaturedAgentByLocation.location.id,
                                                            ref: ref || '',
                                                        },
                                                    }}
                                                    target="__blank"
                                                    className="text-white hover:text-white"
                                                >
                                                    Buy Now
                                                </Link>
                                            </Button>
                                        </div>
                                        <PropertySearchFeaturedAgentCard
                                            agent={previewFeaturedAgentByLocation.agent}
                                            location={previewFeaturedAgentByLocation.location}
                                            agency={
                                                previewFeaturedAgentByLocation.agent &&
                                                previewFeaturedAgentByLocation.agent.agency
                                                    ? previewFeaturedAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={previewFeaturedAgentByLocation.agent.reviewRating}
                                            propertiesConnection={
                                                previewFeaturedAgentByLocation.agent.propertiesConnection
                                            }
                                            showBuyButton
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </>
                        )}

                        <ScreenWidthValueProvider xs={1} sm={2} md={3} lg={2} xl={2} xxl={3}>
                            {(subMatch) => (
                                <PropertySearchResultPaginated
                                    maxItemsPerRow={subMatch}
                                    resultList={dataSource}
                                    searchOptions={searchOptions}
                                    searchFilters={buildQueryVariables(searchOptions)}
                                    pageSize={24}
                                    // loadMore={handleLoadMore}
                                    showMap={showMap}
                                    // loading={loading}
                                    showLoadingMore={showLoadingMore}
                                    isLoadingMore={loadingMore}
                                    isFromSearch={isFromSearch}
                                    totalCount={totalCount}
                                    onChangePagination={onChangePagination}
                                    featuredBroker={hasFeaturedBroker ? featuredBrokerByLocation : null}
                                    showBrokerOnMobile={subMatch === 1}
                                    propertySearchVariables={propertySearchVariables}
                                    hidePropertiesList={hidePropertiesList}
                                />
                            )}
                        </ScreenWidthValueProvider>

                        {!loading && dataSource.length === 0 && (
                            <div
                                className="relative mt-2 rounded bg-white p-6 text-center"
                                style={{ height: 'calc(100vh - 208px)' }}
                            >
                                <div className="center-align">
                                    <img src={emptySearch} alt="empty search results" className="w-5/6" />
                                    <h2 className="mt-2 font-extralight text-gray-500">
                                        <div className="ml-6">
                                            <p className="my-4 text-xl">Sorry, no property results found</p>
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={() => handleDrawer(true)}
                                                icon={<AlertOutlined />}
                                            >
                                                Create alert
                                            </Button>
                                        </div>
                                    </h2>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="absolute top-0 mt-16">
                        <div className="advance-search-map fixed right-0 hidden h-full w-1/2 lg:block">
                            <PropertyMapWithMarkerClusterer
                                featuredAgentId={featuredAgentByLocation?.agent?.id ?? null}
                                markers={markers}
                                isFromSearch={isFromSearch}
                            />
                            {hasFeaturedBroker && isOpenBroker === true && (
                                <div className="absolute right-0 top-0 z-50 m-2 hidden w-64 lg:block">
                                    <PropertySearchBrokerCard
                                        firstName={featuredBrokerByLocation.broker.firstName}
                                        lastName={featuredBrokerByLocation.broker.lastName}
                                        suburbName={featuredBrokerByLocation.location.suburb}
                                        profilePicture={featuredBrokerByLocation.broker.profilePicture}
                                        brokerId={featuredBrokerByLocation.broker.id}
                                        logoUrl={featuredBrokerByLocation.broker.profileLogoUrl}
                                        slugUrl={featuredBrokerByLocation.broker.slugUrl}
                                        logoShape={featuredBrokerByLocation.broker.logoShape}
                                        onClose={() => setIsOpenBroker(false)}
                                        headerPadding="px-2 py-3"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PropertySearchResultMap.propTypes = {
    searchOptions: PropTypes.shape({
        keyword: PropTypes.string,
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        bed: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        price: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        parking: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        landArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        floorArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        bathroom: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        mapLocation: PropTypes.objectOf({
            radius: PropTypes.number,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        location: PropTypes.string,
        locationId: PropTypes.string,
        locationsData: PropTypes.any,
        includeSurrounding: PropTypes.bool,
        sorting: PropTypes.string,
        isIncludeSurrounding: PropTypes.bool,
        isIncludeOffMarket: PropTypes.bool,
        keywords: PropTypes.array,
        group: PropTypes.string,
    }),
    onChangeSorting: PropTypes.func,
    onChangePagination: PropTypes.func,
    isFromSearch: PropTypes.bool,
};

PropertySearchResultMap.defaultProps = {
    searchOptions: {},
    onChangeSorting: () => {},
    onChangePagination: () => {},
    isFromSearch: false,
};

export default PropertySearchResultMap;
