import React from 'react';
import Image from 'next/image';
import banner from '../../assets/banners/realty-find-ba-banner-600x1200.png';
import bannerMobile from '../../assets/banners/realty-find-ba-banner-600x500.png';

type Props = {
    className?: string;
    isMobile?: boolean;
};

const RealtyFindBABanner: React.FC<Props> = ({ className, isMobile = false }) => (
    <div className={className}>
        <a href="https://buyer.realty.com.au/find/directory" target={'_blank'}>
            <a target="_blank" rel="noreferrer">
                {isMobile ? (
                    <Image
                        src={bannerMobile}
                        alt="Realty find buyer agents"
                        layout="intrinsic"
                        width={500}
                        height={600}
                    />
                ) : (
                    <Image src={banner} alt="Realty find buyer agents" layout="intrinsic" width={250} height={500} />
                )}
            </a>
        </a>
    </div>
);

export default RealtyFindBABanner;
